(function () {
  'use strict';

  class CurrentModuleFlowCtrl {
    constructor($mdDialog, CurrentModuleFlow, Groups, $mdToast, $state, Utils) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.Groups = Groups;
      vm.$mdToast = $mdToast;
      vm.$state = $state;
      vm.Utils = Utils;
      vm.obj = CurrentModuleFlow.getObj();
      vm.setTags();
    }
    setTags() {
      let vm = this;
      angular.forEach(vm.obj.module_sets, function (ms) {
        let arr = [];
        ms.current_keywords = [];
        ms.all_keywords = [];
        angular.forEach(ms.module_entries, function (me) {
          angular.forEach(me.keywords, function (kw, po) {
            let word = kw.toLowerCase();
            me.keywords[po] = word;
            if (arr.indexOf(word) === -1) {
              arr.push(word);
            }
          });
        });
        angular.forEach(arr, function (d) {
          ms.all_keywords.push({text: d});
        });
      });
    }
    unset(module_entries) {
      let vm = this,
          payload = {
            id: vm.obj.id,
            current_module_id: module_entries.current_module_id
          };
      vm.Groups.unSetModule(payload)
        .then(() => {
          vm.message('Module unset.');
          module_entries.selected = false;
        });
    }
    applyTag(keyword, moduleSet) {
      if (moduleSet.current_keywords.includes(keyword.text)) {
        moduleSet.current_keywords.splice(moduleSet.current_keywords.indexOf(keyword.text), 1);
      } else {
        moduleSet.current_keywords.push(keyword.text);
      }
      keyword.active = !keyword.active;
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    current(moduleset, module_entries) {
      let vm = this,
          payload = {
            id: vm.obj.id,
            module_id: module_entries.module_id,
            module_set_id: moduleset.id
          };
      vm.Groups.setModules(payload)
        .then(() => {
          vm.message('Module set.');
          module_entries.selected = true;
        });
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
    run(module) {
      let vm = this;
      vm.Utils.setClassroom(vm.obj.classroom_id);
      vm.$state.go('module.resource', {moduleId: module.module_id});
      vm.cancel();
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:CurrentModuleFlowCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('CurrentModuleFlowCtrl', CurrentModuleFlowCtrl);
}());
